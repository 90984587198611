import React, { createRef } from "react";
import "./Main.scss";
import { NavBar } from "../NavBar/NavBar";
import { Banner } from "../Banner/Banner";
import { Introduction } from "../Introduction/Introduction";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import smoothscroll from "smoothscroll-polyfill";
import { Buffet } from "../Buffet/Buffet";
import { StreetFood } from "../StreetFood/StreetFood";
import { AboutUs } from "../AboutUs/AboutUs";
import { Contact } from "../Contact/Contact";
import { Footer } from "../Footer/Footer";
// import NotificationBar from "../components/NotificationBar";
import { WhatsNew } from "../WhatsNew/WhatsNew";
import MenuSelection from "../MenuSelection/MenuSelection";

smoothscroll.polyfill();

class Main extends React.PureComponent {
  constructor(props) {
    super(props);

    this.banner = createRef();
    this.introduction = createRef();
    this.buffet = createRef();
    this.streetFood = createRef();
    this.aboutUs = createRef();
    this.contact = createRef();
    this.whatsNew = createRef();
    this.menuItems = [
      "Ajankohtaista",
      "Ruoasta",
      "Street Food",
      "Meistä",
      "Yhteystiedot",
      "Menu",
    ];
    this.state = {
      menuVisible: false,
      menuPopUpVisible: false,
      width: window.innerWidth,
    };
  }

  _scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 80,
      left: 0,
      behavior: "smooth",
    });
  };

  _toggleMenu = () => {
    this.setState({
      menuVisible: !this.state.menuVisible,
    });
  };

  componentDidMount = () => {
    window.addEventListener("resize", this._handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this._handleResize);
  };

  _handleResize = () => {
    this.setState({ width: window.innerWidth });
  };

  _scrollToItem = (item) => {
    if (this.state.menuVisible) this._toggleMenu();
    switch (item) {
      case "Ajankohtaista":
        this._scrollToRef(this.whatsNew);
        break;
      case "Ruoasta":
        this._scrollToRef(this.buffet);
        break;
      case "Street Food":
        this._scrollToRef(this.streetFood);
        break;
      case "Meistä":
        this._scrollToRef(this.aboutUs);
        break;
      case "Yhteystiedot":
        this._scrollToRef(this.contact);
        break;
      case "Menu":
        this._openMenuSelection();
        break;
      default:
        this._scrollToRef(this.introduction);
        break;
    }
  };

  _openMenuSelection = () => {
    this.setState({
      menuPopUpVisible: true
    });
  }

  _closeMenuSelection = () => {
    this.setState({
      menuPopUpVisible: false
    });
  }

  render() {
    return (
      <div className="App">
        <NavBar
          toggleMenu={this._toggleMenu}
          items={this.menuItems}
          scrollToItem={this._scrollToItem}
          scrollToTop={() => this._scrollToRef(this.banner)}
        />
        {/* <NotificationBar
          onButtonClick={() => this._scrollToRef(this.contact)}
        /> */}
        <Banner
          openMenuSelection={this._openMenuSelection}
          scrollToContacts={() => this._scrollToRef(this.contact)}
          scrollToContent={() => this._scrollToRef(this.introduction)}
          refProp={this.banner}
        />
        <MobileMenu
          scrollToItem={this._scrollToItem}
          items={this.menuItems}
          visible={this.state.menuVisible}
        />
        <Introduction refProp={this.introduction} />
        <WhatsNew scrollToItem={this._scrollToItem} width={this.state.width} refProp={this.whatsNew} />
        <Buffet openMenuSelection={this._openMenuSelection} refProp={this.buffet} />
        <StreetFood refProp={this.streetFood} />
        <AboutUs refProp={this.aboutUs} />
        <Contact refProp={this.contact} />
        {this.state.menuPopUpVisible && <MenuSelection onClose={this._closeMenuSelection} />}
        <div
          onClick={() => (document.location.href = "tel:+358442314945")}
          className="CallToOrder"
        >
          <p>Soita ja tilaa:</p>
          <p>+358 44 231 4945</p>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Main;
