import React from "react";
import "./AboutUs.scss";
import { HighlightBanner } from "../components/HighlightBanner/HighlightBanner";

const StaffMemberBlock = (props) => {
  const {
    name,
    title,
    desc,
    paragraphStyle,
    containerStyle,
    className,
  } = props;
  return (
    <div className={`StaffMember ${className}`} style={containerStyle}>
      <h3 style={paragraphStyle} className="Name">
        {name}
      </h3>
      <h4 style={paragraphStyle} className="Title">
        {title}
      </h4>
      <p style={paragraphStyle} className="Desc">
        {desc}
      </p>
    </div>
  );
};

export const AboutUs = (props) => {
  return (
    <div ref={props.refProp} className="AboutUs">
      <h2>Meistä</h2>
      {/* <p>
        Ajatus aasialaisen street food -ravintolan perustamisesta syntyi
        intohimosta ja rakkaudesta Itä-Aasian street food -henkiseen ruokaan. Me
        haluamme tarjota asiakkaillemme sellaista ruokaa, mitä itse arvostamme
        ja rakastamme. Näiden ajatusten pohjalta syntyi CHU Asian Street Food
        -ravintola.
      </p> */}
      <p>
        Meillä nautit huippulaadukkaasta street food -ruuasta rennossa ja
        kodinomaisessa tunnelmassa. Mottomme on, että hyvän ruuan ei tarvitse
        maksaa paljon: me tarjoamme asiakkaillemme mieleenpainuvia makuelämyksiä
        sopivaan hintaan. Taustavoimana kaikessa näkyy intohimomme ruokaan ja
        sen valmistamiseen. Se saa meidät valitsemaan aina tuoreimmat,
        puhtaimmat raaka-aineet ja varmistamaan, että nautit olostasi paikan
        päällä. Koukuttavat maut ja välitön tunnelma houkuttelevat sinut
        varmasti poikkeamaan myös uudestaan!
      </p>
      <p style={{marginTop: 25}}>
        Olemme aasialaisen ruokakulttuurin edelläkävijöitä ja suunnannäyttäjiä.
        Haluamme kunnioittaa autenttisia valmistusmenetelmiä, mutta yhdistelemme
        niitä uuteen ja luomme jotain aivan uniikkia. Jokainen resepti on
        tiimimme tarkan taustatyön tulos. Jatkuvaan kehitystyöhömme liittyy myös
        ekologiset arvot, jotka näkyvät aina ruoka-aine- ja
        materiaalivalinnoissamme.
      </p>
      <p style={{marginTop: 25}}>
        Tarinamme alkoi kesäisen spontaanista ajatuksesta. Eero ja Xiwen Chun
        idea ravintolan perustamisesta konkretisoitui nopeasti, kun avasimme
        ensimmäisen Chu Asian Street Food -ravintolan Tampereelle vuoden 2020
        tammikuussa. Ravintolan taustalla vaikuttava vahva kokemus vauhditti
        ravintolan perustamista. Intohimoinen kotikokki Eero vastaa yrityksen
        toiminnasta ja kaikesta siitä pienestä, mikä ei asiakkaalle näy, jotta
        ravintolan kokit voivat keskittyä olennaiseen. Molemmat ravintolan
        pääkokit ovat hankkineet ainutlaatuiset taitonsa Kiinassa ja tehneet
        pitkän uran keittiössä myös Suomessa. Pääkokki Xiwen Chu tuo maut
        eteläisestä Kiinasta Kantonin alueelta ja toinen pääkokkimme Limin Lan
        täydentää niitä Pohjois-Kiinan makuelämyksillä ja mausteilla.
        Asiakkaamme saavat nauttia myös japanilaisesta ja korealaisesta
        keittiöstä – menuumme yhdistelee parhaimpia itä-aasialaisia makuja.
      </p>
      <hr />
      <div className="StaffContainer">
        <div className="Separator" />
        <StaffMemberBlock
          className="Left"
          name="Eero Chu"
          title="Perustajajäsen"
          desc={`Yhtenä ravintolan perustajista Eero toimii ravintolan kulissien takana. Hänet saattaa myös tavata ravintolassa palvelemassa asiakkaita.`}
        />
        <StaffMemberBlock
          className="Center"
          name="Xiwen Chu"
          title="Perustajajäsen & Pääkokki"
          desc={`
          Xiwenin ainutlaatuiset keittiöntaidot ovat lähtöisin Kiinasta, Kantonin provinssista. Xiwenillä on yli 25 vuoden kokemus aasialaisesta keittiöstä. Ravintolamme pääkokkina Xiwen tuo ruokalistaamme herkullisia Street Food -henkisiä makuja. 
          `}
        />
        <StaffMemberBlock
          className="Right"
          name="Limin Lan"
          title="Ravintolan pääkokki"
          desc={`Limin tuo osaamisensa ravintolaamme suoraan Pohjois-kiinalaisesta ruokakeittiöstä. Hänen erikoisuutensa ovat mykyt sekä itäaasialaisittain maustetut tuoresalaatit.`}
        />
      </div>
      <HighlightBanner
        imgClassName="Image3"
        text={
          <React.Fragment>
            ”Haluamme tehdä aasialaisen street food -kulttuurin tutuksi
            jokaiselle aloittaen ensimmäisestä ravintolastamme Tampereen
            Kalevassa.”
          </React.Fragment>
        }
      />
    </div>
  );
};
