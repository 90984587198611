import React from "react";
import "./Footer.scss";
import { FaFacebook, FaInstagram} from 'react-icons/fa';

export const Footer = props => {
  return (
    <div className="Footer">
        <p>CHU Asian Street Food 2024</p>
        <div className="Icons">
            <a href="https://facebook.com/chuasianstreetfood">
                <FaFacebook className="Icon" />
            </a>
            <a href="https://instagram.com/chuasianstreetfood">
                <FaInstagram className="Icon"/>
            </a>
        </div>
    </div>
  );
};
