import React from "react";
import "./HighlightBanner.scss";
import { Button } from "../Button/Button";

export const HighlightBanner = props => {
    const {buttonText, text, mirror, linkRef, onClick, imgClassName} = props;
  return (
    <div ref={props.refProp} className="HighlightBanner">
     {!mirror && <div className="ImageContainer">
        <image className={imgClassName} />
      </div>}
      <div className={`TextContainer ${mirror ? 'Mirror' : ''}`}>
        <p style={{ marginBottom: buttonText ? 30 : 0}}>
          {text}
        </p>
        {buttonText && <Button onClick={onClick} linkRef={linkRef} text={buttonText} />}
      </div>
      {mirror && <div className="ImageContainer ">
        <image className={imgClassName} />
      </div>}
    </div>
  );
};
