import React from "react";
import "./Introduction.scss";

export const Introduction = props => {
  return (
    <div ref={props.refProp} className="Introduction">
      <p>
        Upealla paikalla Tampereen Kalevassa sijaitseva trendikäs ja
        tunnelmallinen ravintolamme tarjoaa aitoja, uniikkeja ja herkullisia
        itä-aasialaisia street food -makuja suoraan aasialaisesta
        ammattikeittiöstä. Tule ja koe oma street food -makuelämyksesi!
      </p>
      <div className="ImageRow">
        <div className="ImageContainer">
          <image className="Image1">
            <h2>AUTENTTINEN</h2>
          </image>
        </div>
        <div className="ImageContainer">
        <image className="Image3">
            <h2>MAUKAS</h2>
          </image>
        </div>
        <div className="ImageContainer">
        <image className="Image2">
            <h2>LAADUKAS</h2>
          </image>
        </div>
      </div>
    </div>
  );
};
