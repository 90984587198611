import React from "react";
import "./StreetFood.scss";
import { HighlightBanner } from "../components/HighlightBanner/HighlightBanner";

export const StreetFood = props => {
  return (
    <div ref={props.refProp} className="StreetFood">
      <h2>Street Food</h2>
      <p>
        Street Foodilla eli suomeksi katukeittiöllä viitataan perinteisesti
        katujen varrella valmistettuihin ruokiin, jotka ovat erityisen
        suosittuja Itä-Aasiassa – esimerkiksi Kiinassa, Japanissa ja Koreassa.
        Street foodin ydin on syömisen helppous sekä aitojen herkullisten
        makujen tarjoaminen.
      </p>
      <HighlightBanner
        imgClassName="Image2"
        mirror={true}
        text={
          <React.Fragment>
            Parhaat ruoat syntyvät osaavissa käsissä,
            <span className="Bold"> laadukkaista ja tuoreista raaka-aineista.</span>
          </React.Fragment>
        }
      />
    </div>
  );
};
