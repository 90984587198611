import React from "react";
import "./Button.scss";

export const Button = props => {
  const { text, style, onClick, linkRef } = props;
  return linkRef ? (
    <a href={linkRef} rel="noopener noreferrer" target="_blank" style={style} className="Button">
      <h3>{(text || "").toUpperCase()}</h3>
    </a>
  ) : (
    <button onClick={onClick} style={style} className="Button">
      <h3>{(text || "").toUpperCase()}</h3>
    </button>
  );
};
