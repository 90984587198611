import React from "react";
import "./Banner.scss";
import {
  FaPhoneSquare,
  FaMapMarkerAlt,
  FaArrowCircleDown,
} from "react-icons/fa";
import { Button } from "../components/Button/Button";
import Texty from "rc-texty";
import logo from "../assets/chu_logo_large.png";

const LabelWithIcon = props => {
  const { text, icon, linkRef } = props;
  return (
    <div className="LabelWithIcon">
      {icon}
      {linkRef ? <a href={linkRef}>{text}</a> : <h3>{text}</h3>}
    </div>
  );
};

const iconProps = {
  className: "Icon",
  size: 25,
  style: {
    marginRight: 10
  }
};

export class Banner extends React.PureComponent {
  animTimer;
  texts = ["KALEVASSA", "PARHAAT", "ITÄ-AASIAN MAUT"];
  textIndex = 0;
  constructor(props) {
    super(props);
    this.state = {
      text: ""
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        text: this.texts[this.textIndex]
      },
      () => {
        this.animTimer = setInterval(() => {
          this.setState(
            {
              text: ""
            },
            () => {
              setTimeout(() => {
                this.textIndex + 1 < this.texts.length
                  ? (this.textIndex += 1)
                  : (this.textIndex = 0);

                this.setState({
                  text: this.texts[this.textIndex]
                });
              }, 1000);
            }
          );
        }, 4000);
      }
    );
  };

  render() {
    const {scrollToContacts, openMenuSelection} = this.props;
    return (
      <div ref={this.props.refProp} className="Banner">
        <img className="BigLogo" alt="CHU logo" src={logo} />
        <Texty className="Header">{this.state.text}</Texty>
        <LabelWithIcon
          text={"Sammonkatu 44, 33540, Tampere"}
          icon={<FaMapMarkerAlt {...iconProps} />}
        />
        <LabelWithIcon
          linkRef={'tel:+358442314945'}
          text={"+358 44 231 4945"}
          icon={<FaPhoneSquare {...iconProps} />}
        />
        <div className="ButtonContainer">
          <Button
            onClick={openMenuSelection}
            style={{ color: "white", borderColor: "white" }}
            text={"Katso menu"}
          />
          
          <Button
            style={{ marginLeft: 20, color: "white", borderColor: "white" }}
            onClick={scrollToContacts}
            text={"Aukioloajat"}
          />
        </div>
        <button
          onClick={this.props.scrollToContent}
          className="DownArrow"
          href="."
        >
          <FaArrowCircleDown size={20} />
        </button>
      </div>
    );
  }
}
