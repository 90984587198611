import React from "react";
import "./Buffet.scss";
import { HighlightBanner } from "../components/HighlightBanner/HighlightBanner";
import { Button } from "../components/Button/Button";

export const Buffet = (props) => {
  const { openMenuSelection } = props;
  return (
    <div ref={props.refProp} className="Buffet">
      <div className="TakeAway">
        <h2>Ruoasta</h2>
        <p style={{ fontSize: "1.3em" }}>
          Meillä nautit kattavasta lounasbuffetista (tarjolla arkisin klo
          11.00-14.00) ja käsintehdyistä nuudeleista paikan päällä tai take
          awayna.
        </p>
        <p style={{ marginTop: 20 }}>
          Arkisin lounasbuffetin ollessa tarjolla À la carte -menun annoksia
          on mahdollista tilata vasta klo 14.00 jälkeen.
        </p>
        <p
          className="OrderLabel"
          onClick={() => (document.location.href = "tel:+358442314945")}
          style={{ marginTop: 30 }}
        >
          Soita ja tilaa: +358 44 231 4945
        </p>
        {/* <p>Kaikki annokset valmistetaan tilauksesta.</p> */}
        {/* <div
          style={{ backgroundColor: "#C65257" }}
          className="SpecialPrices white"
        >
          <p>Lounas arkisin klo 11:00-15:00, jolloin valitut annokset hintaan 10,70 € (koskee myös take awayta).</p>
          <p style={{ marginTop: 10, marginBottom: 0 }}>
            Opiskelijakortilla hinnat -10% (normaalihintaisista).
          </p>
        </div> */}
        <Button
          style={{ marginTop: 35, marginBottom: 10 }}
          onClick={openMenuSelection}
          text="Katso menu"
        />
        <div className="Foodora">
          <p>Ruoan tilaus kotiin onnistuu myös Foodoran kautta:</p>
          <img
            onClick={() =>
              window.open(
                "https://www.foodora.fi/restaurant/s2wg/chu-asian-street-food",
                "_blank"
              )
            }
            src={require("../assets/foodora.png")}
            alt="Foodora"
          />
        </div>
        <div className="SpecialPrices">
          <h3 className="SpecialPriceInfo">
            Meillä käyvät myös seuraavat maksutavat:
          </h3>
          <div className="LogoContainer">
            <img alt="Edenred" src={require("../assets/edenred.png")} />
            <img alt="Smartum" src={require("../assets/smartum.png")} />
            <img alt="Eazybreak" src={require("../assets/eazybreak.png")} />
            <img alt="E-passi" src={require("../assets/epassi.png")} />
          </div>
        </div>
      </div>
      {/* <div className="BuffetContainer">
        <h2>Buffet</h2>
        <p>
          Suuri street food buffet -valikoimamme koostuu 8 lämpimästä
          ruokalajista, kuudesta tuoreesta salaattivaihtoehdosta ja keitosta.
          Kattavasta ja monipuolisesta buffetistamme löydät Itä-Aasian parhaat
          maut.
        </p>
        <div className="PriceRow">
          <div className="PriceContainer">
            <h2>11,50 €</h2>
          </div>
        </div>
      </div> */}
      <HighlightBanner
        imgClassName="Image1"
        buttonText="Katso menu"
        onClick={openMenuSelection}
        text={
          <React.Fragment>
            Jokainen valmistamamme annos tehdään talon uniikilla reseptillä{" "}
            <span className="Bold">alkuperäisiä valmistusmenetelmiä</span>{" "}
            kunnioittaen.
          </React.Fragment>
        }
      />
    </div>
  );
};
