import React from "react";
import "./MobileMenu.scss";

export const MobileMenu = props => {
  const { items, scrollToItem, visible } = props;
  const ITEM_HEIGHT = 80;
  const _getNavBarItems = () => {
    return items.map((obj, index) => {
      return (
        <React.Fragment key={index}>
          <li className="MobileItem">
            <button className="ItemButton" onClick={() => scrollToItem(obj)}>
              {obj.toUpperCase()}
            </button>
          </li>
          {index !== items.length - 1 && (
            <hr style={{ opacity: 0.1, marginRight: 40, marginLeft: 40 }} />
          )}
        </React.Fragment>
      );
    });
  };
  return (
    <div
      style={{ height: visible ? items.length * ITEM_HEIGHT + 10 : 0 }}
      className="MobileMenu"
    >
      <ul>{_getNavBarItems()}</ul>
    </div>
  );
};
