import React from "react";
import "./Contact.scss";
import GoogleMapReact from 'google-map-react';

const renderMarker = (map, maps) => {
  new maps.Marker({
    map: map,
    position: new maps.LatLng(61.495970, 23.803990),
  });
};

export const Contact = props => {

  const defaultProps = {
    center: {
      lat: 61.495970,
      lng: 23.803990
    },
    zoom: 15
  };
  return (
    <div ref={props.refProp} className="Contact">
      <h2>Yhteystiedot</h2>
      <div className="ContactsContainer">
      <div className="Schedule">
          <p className="ScheduleHeader">Palvelemme</p>
          <p>MA-PE 11:00-20:00</p>
          <p>LA 11:00-20:00</p>
          <p>SU SULJETTU</p>
          <p className="small">Keittiö suljetaan noin 30 min ennen sulkemisaikaa.</p>
        </div>
        <div style={{height: 350, minWidth: "33%"}} className="Map">
          <GoogleMapReact 
          bootstrapURLKeys={{
            key: "AIzaSyB978S-dKPRFAB3hJR5mWf3X_T0K3ifZ70"
          }}
          defaultCenter={defaultProps.center}
          resetBoundsOnResize={true}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
          on
          />
        </div>
        <div className="Address">
          <p>Sammonkatu 44</p>
          <p>33540 Tampere</p>
          <a href="mailto:asiakaspalvelu@chu.fi">asiakaspalvelu@chu.fi</a>
          <a href="tel:+358442314945">+358 44 231 4945</a>
        </div>
      </div>
    </div>
  );
};
