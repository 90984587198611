import React from "react";
import "./NavBar.scss";
import logo from "../assets/chu_logo_small.png";
import { FaBars } from "react-icons/fa";

export class NavBar extends React.PureComponent {
  _getNavBarItems = () => {
    const {scrollToItem} = this.props;
    return this.props.items.map((obj, index) => {
      return (
        <li key={index}>
          <button className="Item" onClick={() => scrollToItem(obj)}>
            {obj.toUpperCase()}
          </button>
        </li>
      );
    });
  };

  render() {
    return (
      <div className="NavBar">
        <button className="Logo">
          <img
            onClick={this.props.scrollToTop}
            alt={"CHU logo"}
            src={logo}
            className="Logo"
          />
        </button>
        <ul className="ItemList">{this._getNavBarItems()}</ul>
        <button onClick={this.props.toggleMenu} className="Hamburger">
          <FaBars color="white" size={30} />
        </button>
      </div>
    );
  }
}
