import React from "react";

import "./MenuSelection.scss";

const MenuSelection = (props) => {
  const { onClose } = props;
  return (
    <div id="menu-selection">
      <div
        onClick={onClose}
        style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}
      />
      <div className="card">
        <h2>Minkä menun haluaisit avata?</h2>
        <hr />
        <div
          onClick={() => {
            onClose();
            window.open(process.env.PUBLIC_URL + "/buffetmenu.pdf", "_blank");
          }}
        >
          Lounasbuffet
        </div>
        <div
          onClick={() => {
            onClose();
            window.open(process.env.PUBLIC_URL + "/lauantaibuffet.pdf", "_blank");
          }}
        >
          Lauantaibuffet
        </div>
        <div
          onClick={() => {
            onClose();
            window.open(process.env.PUBLIC_URL + "/ala_carte_take_away.pdf", "_blank");
          }}
        >
          À la carte/Take Away
        </div>
        {/* <div
          onClick={() => {
            onClose();
            window.open(process.env.PUBLIC_URL + "/take_away.pdf", "_blank");
          }}
        >
          Take Away
        </div> */}
      </div>
    </div>
  );
};

export default MenuSelection;
