import React from "react";
import Iframe from "react-iframe";

import "./WhatsNew.scss";

export const WhatsNew = (props) => {
  const width = Math.min(props.width - 100, 500);
  return (
    <div ref={props.refProp} className="WhatsNew">
      <div className="WhatsNew">
        <h2>Ajankohtaista</h2>
        {/* <div className="fromStores">
          <h3>
            Suosittu lounasbuffet tekee paluun 25.10. alkaen, tervetuloa
            herkuttelemaan!
          </h3>
          <p>
            Kattava buffet tarjolla arkisin klo 11:00-15:00 välisenä aikana.
          </p>
          <Button
            style={{ marginTop: 35, marginBottom: 10 }}
            onClick={() =>
              window.open(
                process.env.PUBLIC_URL + "/lounasbuffet.pdf",
                "_blank"
              )
            }
            text="Katso menu"
          />
        </div> */}
        <p className="facebook">Lue uusimmat päivityksemme Facebookista:</p>
        <Iframe
          url={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fchuasianstreetfood%2F&tabs=timeline&width=${width}&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=244230833436024`}
          display="initial"
          position="relative"
          width={width}
          height={500}
          scrolling="no"
          allow={["encrypted-media"]}
        />
      </div>
    </div>
  );
};
